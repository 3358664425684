<template>
  <div id="board" v-loading="isBoardLoading">
    <div class="board-item">
      <div class="board-item-title lightgrey">Not Started</div>
      <draggable
        class="list-group not-started"
        data-id="1"
        data-listname="notStarted"
        :list="notStarted"
        group="board"
        @end="handleDragEnd"
      >
        <div
          class="list-group-item"
          v-for="(item, index) in notStarted"
          :key="index"
        >
          <BoardOpportunityCard :item="item"></BoardOpportunityCard>
        </div>
      </draggable>
    </div>
    <div class="board-item">
      <div class="board-item-title lavender">Discussion</div>
      <draggable
        class="list-group discussion"
        data-id="2"
        data-listname="discussion"
        :list="discussion"
        group="board"
        @end="handleDragEnd"
      >
        <div
          class="list-group-item"
          v-for="(item, index) in discussion"
          :key="index"
        >
          <BoardOpportunityCard :item="item"></BoardOpportunityCard>
        </div>
      </draggable>
    </div>
    <div class="board-item">
      <div class="board-item-title lightyellow">Lease/Proposal Sent</div>
      <draggable
        class="list-group lease-proposal-sent"
        data-id="3"
        data-listname="proposalSent"
        :list="proposalSent"
        group="board"
        @end="handleDragEnd"
      >
        <div
          class="list-group-item"
          v-for="(item, index) in proposalSent"
          :key="index"
        >
          <BoardOpportunityCard :item="item"></BoardOpportunityCard>
        </div>
      </draggable>
    </div>
    <div class="board-item">
      <div class="board-item-title green">Closed-Won</div>
      <draggable
        class="list-group closed-won"
        data-id="4"
        data-listname="closedWon"
        :list="closedWon"
        group="board"
        @end="handleDragEnd"
      >
        <div
          class="list-group-item"
          v-for="(item, index) in closedWon"
          :key="index"
        >
          <BoardOpportunityCard :item="item"></BoardOpportunityCard>
        </div>
      </draggable>
    </div>
    <div class="board-item">
      <div class="board-item-title lightred">Closed-Lost</div>
      <draggable
        class="list-group closed-lost"
        data-id="5"
        data-listname="closedLost"
        :list="closedLost"
        group="board"
        @end="handleDragEnd"
      >
        <div
          class="list-group-item"
          v-for="(item, index) in closedLost"
          :key="index"
        >
          <BoardOpportunityCard :item="item"></BoardOpportunityCard>
        </div>
      </draggable>
    </div>
    <ClosedLostReasonDialog
      v-if="showClosedLostReasonDialog"
      :show-dialog.sync="showClosedLostReasonDialog"
      :lease-id="losingClosedData.item.id"
      @cancel="handleClosedLostDialogCancel"
      @success="handleClosedLostReasonSuccess"
    ></ClosedLostReasonDialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  apiGetRentalOpportunity,
  apiUpdateRentalOpportunity,
} from "../../../API/api.js";
export default {
  components: {
    draggable,
    ClosedLostReasonDialog: () => import("./ClosedLostDialog.vue"),
    BoardOpportunityCard: () => import("./BoardOpportunityCard.vue"),
  },
  props: {
    newOpportunityData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      isBoardLoading: false,
      showClosedLostReasonDialog: false,
      rentalOpportunities: [],
      boardItems: {
        notStarted: 1,
        discussion: 2,
        proposalSent: 3,
        closedWon: 4,
        closedLost: 5,
      },
      notStarted: [],
      discussion: [],
      proposalSent: [],
      closedWon: [],
      closedLost: [],
      losingClosedData: {},
    };
  },
  watch: {
    newOpportunityData: {
      immediate: true,
      handler: function (nv) {
        if (nv && nv.id) {
          this.notStarted.unshift(nv);
          this.$emit("update:newOpportunityData", {});
        }
      },
    },
  },
  computed: {},
  async mounted() {
    try {
      this.isBoardLoading = true;
      const response = await apiGetRentalOpportunity();
      if (!response) {
        throw new Error(
          "Unable to get the rental opportunities at the moment."
        );
      }
      const { results } = response;
      this.rentalOpportunities = results;
      this.notStarted = this.getBoardItemList("not_started");
      this.discussion = this.getBoardItemList("discussion");
      this.proposalSent = this.getBoardItemList("proposal_sent");
      this.closedWon = this.getBoardItemList("closed_won");
      this.closedLost = this.getBoardItemList("closed_lost");
    } catch (error) {
      if (error && error.message) {
        this.$message.error(error.message);
      }
    } finally {
      this.isBoardLoading = false;
    }
  },
  methods: {
    getBoardItemList(code) {
      return this.rentalOpportunities.length
        ? this.rentalOpportunities.filter((el) => el.status === code)
        : [];
    },
    handleClosedLostDialogCancel() {
      this.showClosedLostReasonDialog = false;
      const { dragData, item } = this.losingClosedData;
      this[dragData.fromList].splice(dragData.oldIndex, 0, item);
      this[dragData.toList].splice(dragData.newIndex, 1);
    },
    handleClosedLostReasonSuccess({ id, note }) {
      const idx = this.closedLost.findIndex((el) => el.id === id);
      this.closedLost[idx].note = note;
    },
    handleDragEnd(e) {
      const currentItem = e.item._underlying_vm_;
      const { oldIndex, newIndex } = e;
      const {
        from: {
          dataset: { id: fromDataId, listname: fromList },
        },
      } = e;
      const {
        to: {
          dataset: { id: toDataId, listname: toList },
        },
      } = e;
      if (fromDataId === toDataId) {
        return;
      }
      const dragData = {
        oldIndex,
        newIndex,
        fromDataId,
        toDataId,
        fromList,
        toList,
      };
      if (Number(toDataId) === this.boardItems.notStarted) {
        const payload = {
          status: "not_started",
        };
        this.handleBoardItemUpdate(currentItem, payload, dragData);
      }
      if (Number(toDataId) === this.boardItems.discussion) {
        const payload = {
          status: "discussion",
        };
        this.handleBoardItemUpdate(currentItem, payload, dragData);
      }
      if (Number(toDataId) === this.boardItems.proposalSent) {
        const payload = {
          status: "proposal_sent",
        };
        this.handleBoardItemUpdate(currentItem, payload, dragData);
      }
      if (Number(toDataId) === this.boardItems.closedWon) {
        const payload = {
          status: "closed_won",
        };
        this.handleBoardItemUpdate(currentItem, payload, dragData);
      }
      if (Number(toDataId) === this.boardItems.closedLost) {
        // open the closed lost reason dialog and process further acc. to the dialog.
        this.losingClosedData = {
          item: currentItem,
          dragData,
        };
        this.showClosedLostReasonDialog = true;
      }
    },
    async handleBoardItemUpdate(item, payload, dragData) {
      const { id } = item;
      try {
        const response = await apiUpdateRentalOpportunity(id, payload);
        const { status } = response;
        const statusText = status
          .split("_")
          .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
          .join(" ");
        this.$message.success(`Status changed to ${statusText}`);
      } catch (error) {
        this[dragData.fromList].splice(dragData.oldIndex, 0, item);
        this[dragData.toList].splice(dragData.newIndex, 1);
        this.$message.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#board {
  background: #f8f8f8;
  border-radius: 12px;
  overflow-x: scroll;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;

  .board-item {
    height: 60vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: whitesmoke;
    .list-group {
      overflow-y: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .board-item-title {
      padding: 4px 12px;
      margin-bottom: 10px;
      border-radius: 20px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #4d4d4d;
    }
    .lightgrey {
      background: #e1e4e8;
    }
    .lavender {
      background: #f0e7f6;
    }

    .lightyellow {
      background: #fffcdc;
    }
    .green {
      background: #4caf50;
    }

    .lightred {
      background: rgba(202, 46, 46, 0.53);
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(5, minmax(300px, 1fr));
    grid-gap: 10px;
  }
}
</style>
